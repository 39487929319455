.badgeIconContainer {
  position: relative;
  margin-right: 21px;
  cursor: pointer;
  top: 0;
}

.icon {
  width: 32px;
  height: 32px;
}

.icon svg {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.badge {
  position: absolute;
  top: -6px;
  right: -7px;
  background: #cf2c47;
  color: white;
  font-size: 12px;
  line-height: 1;
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
}

.badge span {
  position: relative;
  top: -2px;
  right: 1px;
}

@media screen and (max-width: 992px) {
  .badgeIconContainer {
    margin-right: 15px;
  }
}
