.buttonStyle {
  text-decoration: none;
  border-radius: 4px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 26px;
  cursor: pointer;
  opacity: 0.5;
  pointer-events: none;
}

.buttonActiveStyle {
  opacity: 1;
  pointer-events: all;
}
