.inputItem {
  margin-bottom: 20px;
}

.inputItem label {
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 11px;
}

.inputItem input {
  width: 100%;
  border: 2px solid #efeff0;
  height: 48px;
  padding: 0 16px;
  border-radius: 4px;
  appearance: none;
  min-width: 1px;
}

.inputItem input::placeholder {
  color: #aeb7bb;
  font-size: 14px;
}

.nameWrapper {
  display: flex;
}

.nameWrapper .inputWrapper {
  margin-right: 10px;
  width: calc(50% - 5px);
}

.nameWrapper .inputWrapper:last-child {
  margin-right: 0;
}

.inputWrapper {
  position: relative;
}

.nameWrapper .inputWrapper {
  margin-right: 10px;
  width: calc(50% - 5px);
}

.nameWrapper .inputWrapper:last-child {
  margin-right: 0;
}
