.resetPasswordContainer {
  margin-top: 88px;
  max-width: 500px;
}

.sectionHeading {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 50px;
}
