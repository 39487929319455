.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  background-color: #f5f5f5;
  color: #333;
}

.title {
  font-size: 8rem;
  font-weight: bold;
  color: #cf2c47;
  margin-bottom: 0.5rem;
}

.message {
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 2rem;
}

.button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #cf2c47;
  border: none;
  border-radius: 0.25rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.button:hover {
  background-color: #a31f35;
}
