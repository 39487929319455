.headerTitle {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.title {
  margin: 0 10px 0 0;
  font-size: 24px;
  font-weight: 500;
  color: #cf2c47;
}

.editIcon {
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.editIcon svg {
  fill: #cf2c47;
  width: 100%;
  height: 100%;
}

.permissionStatusContainer {
  display: flex;
  align-items: flex-end;
}
