.heading {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.headerTitle {
  display: flex;
  align-items: center;
  margin-top: 50px;
  width: 400px;
  margin-right: 10px;
}

.editIcon {
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: 5px;
}

.editIcon svg {
  fill: var(--red-color);
  width: 100%;
  height: 100%;
}

.meetDate {
  margin-top: 10px;
  color: var(--bright-grey-color);
  font-size: 20px;
  font-weight: bold;
}

.imageDescriptionContainer {
  display: flex;
}

.imageContainer {
  position: relative;
  width: 400px;
  margin-top: 10px;
  margin-right: 10px;
}

.image {
  width: 100%;
  position: relative;
  padding-top: 33.3%;
}

.image img {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  object-fit: cover;
}

.tagWrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--red-color);
  display: flex;
  color: var(--white-color);
  align-items: center;
  padding: 5px 24px 5px 12px;
  border-radius: 4px 0 4px 0;
}

.trainingTagWrapper {
  background-color: var(--green-color);
  padding-right: 16px;
}

.tagImage {
  width: 20px;
  height: 20px;
  margin-right: 7px;
  font-weight: 700;
}

.tagImage svg {
  width: 20px;
  height: 20px;
  fill: var(--white-color);
}

.tagText {
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.details {
  margin-top: 20px;
}

.detail {
  display: flex;
  margin-bottom: 20px;
}

.detailTitle {
  min-width: 200px;
  font-size: 16px;
  font-weight: bold;
  color: var(--bright-black-color);
}

.detailValue {
  font-size: 16px;
  color: var(--red-color);
  font-weight: 600;
}
