.inputItem {
  margin-bottom: 20px;
}

.inputItem label {
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 11px;
}

.inputItem input {
  width: 100%;
  border: 2px solid #efeff0;
  height: 48px;
  padding: 0 16px;
  border-radius: 4px;
  appearance: none;
  min-width: 1px;
}

.inputItem input::placeholder {
  color: #aeb7bb;
  font-size: 14px;
}

.dateHasValue {
  color: black !important;
}

input[type='date'],
input[type='time'] {
  color: #aeb7bb;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  width: 100%;
}

.inputWrapper {
  position: relative;
}

.datePickerWrapper {
  width: 176px;
}

img.calendarImage {
  width: 19px;
  height: 19px;
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  cursor: pointer;
  background: white;
  pointer-events: none;
}
