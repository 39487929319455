.clubDescription {
  margin-bottom: 90px;
}

.clubDescription label {
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
}

.clubDescription textarea {
  display: block;
  width: 100%;
  height: 168px;
  font-size: 14px;
  border: 2px solid #efeff0;
  border-radius: 6px;
  padding: 15px 14px;
  margin-bottom: 5px;
}

.clubDescription span {
  margin-right: 0;
  margin-left: auto;
  display: block;
  width: fit-content;
  font-size: 12px;
}
