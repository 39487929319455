.options {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .options {
    margin-bottom: 10px;
  }
}
