.container {
  margin-top: 70px;
}

.title {
  margin: 0 10px 0 0;
  font-size: 24px;
  font-weight: 500;
  color: #cf2c47;
}
