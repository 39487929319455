.findClubsContainer {
  margin-top: 88px;
  max-width: 500px;
}

.sectionHeading {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 50px;
}

a.logIn {
  background-color: #cf2c47;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-width: 361px;
}

.inputItem {
  margin-bottom: 256px;
}

.inputWrapper {
  position: relative;
}

.inputItem input {
  width: 100%;
  border: 2px solid #efeff0;
  height: 60px;
  padding: 0 16px;
  border-radius: 4px;
  appearance: none;
  min-width: 1px;
  font-size: 20px;
}

.inputItem input::placeholder {
  color: #aeb7bb;
}

img.iconEyeCross {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 18px;
  cursor: pointer;
}

.noteText {
  font-size: 16px;
  max-width: 433px;
  line-height: 1.5;
  margin-bottom: 36px;
}

.noteText span {
  font-weight: bold;
}

@media screen and (max-width: 992px) {
  a.logIn {
    max-width: unset;
  }
}
