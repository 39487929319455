.mobileMenu {
  z-index: 2;
  height: 100%;
}

.mobileMenuWrapper {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  max-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  transform: translateX(-100%);
  transition: all 0.3s;
  z-index: 2;
}

.mobileMenuActive .mobileMenuWrapper {
  transform: translateX(0);
}

.mobileMenu .topPart {
  display: flex;
  align-items: center;
  padding: 36px 21px 31px 25px;
  border-bottom: 2px solid #efeff0;
}

.mobileMenu .hamburgerImage {
  width: 50px;
  height: 50px;
  display: flex;
  flex: none;
  margin-right: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 1px solid #efeff0;
  border-radius: 2px;
}

.mobileMenu .hamburgerImage svg {
  width: 32px;
  height: 32px;
  display: block;
  object-fit: contain;
}

.mobileMenu .logoImage {
  width: 150px;
  height: 50px;
}

.mobileMenu .logoImage img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.mobileMenu .mobileMenuClose {
  display: none;
}

.mobileMenu .mobileMenuOverflowWrapper {
  overflow: auto;
  max-height: max-content;
  height: auto;
}

.mobileMenu .mobileMenuBottomButtons {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-weight: 500;
  padding: 10px 26px 25px 29px;
  background: white;
}

.mobileMenu .mobileMenuBottomButton {
  display: block;
  font-size: 14px;
  color: #889398;
  text-align: center;
  padding: 22px 15px;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: #efeff0;
  text-decoration: none;
}

.mobileMenu .mobileMenuBottomButtonActive {
  background-color: #cf2c47;
  color: white;
  cursor: pointer;
}

.mobileMenu .mobileMenuBottomButton:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 992px) {
  .mobileMenu .hamburgerImage {
    margin-right: 57px;
  }

  .mobileMenu .topPart {
    padding: 41px 21px 9px 25px;
  }

  .mobileMenu .logoImage {
    width: 144px;
    height: 44px;
  }

  .mobileMenu .mobileMenuClose {
    display: block;
    position: absolute;
    top: 26px;
    right: 24px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }

  .mobileMenu .mobileMenuClose svg {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
}
