.historyPR {
  display: flex;
  align-items: flex-end;
  margin-top: 5px;
  font-weight: 600;
  font-size: 18px;
}

.historyPR img {
  margin-left: 15px;
}
