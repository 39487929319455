.avatarWrapper {
  width: 100%;
  margin-bottom: 40px;
  padding: 26px 94px 115px 100px;
  border-radius: 6px;
  background-color: #f2f3f3;
}

.avatar {
  width: 100%;
  padding-top: 100%;
  border-radius: 999px;
  overflow: hidden;
  position: relative;
  margin-bottom: 19px;
}

.avatar img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  position: absolute;
  top: 0;
}

.updateButton {
  color: #cf2c47;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 7px;
  cursor: pointer;
}

.removeButton {
  color: #889398;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .avatarWrapper {
    padding: 26px 25px 115px;
  }
}
