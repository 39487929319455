.addImageWrapper {
  width: 210px;
  height: 210px;
  border-radius: 1000px;
  box-shadow: 0 0 16px rgba(225, 237, 243, 0.5);
  border: 3px solid #efeff0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ffffff 0 0 no-repeat padding-box;
  margin-bottom: 60px;
  cursor: pointer;
}

.addImage {
  width: 28px;
  height: 28px;
  margin-bottom: 14px;
}

.addImage img {
  display: block;
  width: 100%;
  height: 100%;
}

.addImageText {
  font-size: 14px;
  color: #313131;
}
